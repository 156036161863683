body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

nav {
  background-color: aliceblue;
  padding: 16px 32px;
  margin-bottom: 32px;
}

nav a {
  margin-right: 16px;
}

.recommended-actions .Table-module_header-row__IVYCL {
  display: none !important;
}
.recommended-action-cluster-link .Table-module_header-row__IVYCL {
  display: contents !important;
}
.recommended-action-cluster-link
  .Table-module_table-wrapper__w2HwG
  .Table-module_table__-ZPCW
  .Table-module_header-row__IVYCL
  .Table-module_header-cell__RdD92 {
  top: var(--table-top-fixed, 0px);
}
.MuiBox-root {
  padding: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(216, 216, 216) !important;
  border-radius: 10px;
}

.renewable-energy-percentage
  .Table-module_table-wrapper__w2HwG
  .Table-module_table__-ZPCW
  .Table-module_header-row__IVYCL
  .Table-module_header-cell__RdD92 {
  top: var(--table-top-fixed, 0px) !important;
}

/* .recommended-action-cluster-link .Table-module_table-wrapper__w2HwG .Table-module_table__-ZPCW .Table-module_body__DiRYh .Table-module_row__H0wL1.Table-module_highlighted-row__44zu6 .Table-module_cell__ZfnbG {
    background-color: white !important;
} */
